/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
footer {
  text-align: left;
  width: 100%;
  min-width: $min-width;
  order: 3;
  -webkit-order: 3;
}

.footer {
  background: #FFFFFF;
  z-index: 1;
  padding-bottom: 16px;

  &-top {
    padding: 60px 0 57px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e5e7eb;

    &__social {
      span {
        font-size: 16px;
        line-height: 170%;
        color: #222222;
        display: block;
        margin-bottom: 8px;
      }

      ul {
        display: flex;

        li {
          margin-right: 10px;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px;

          a {
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba($color: #ffffff, $alpha: 0.1);
            border: 1px solid #e5e7eb;
            border-radius: 8px;

            i {
              &:before {
                color: #222222;
                transition: $transition;
              }
            }
          }
        }
      }
    }

    &__logo {
      margin-left: 80px;
      margin-top: 10px;

      & a img {
        border-radius: 100%;
      }
    }

    &__payments {
      padding-right: 16px;

      span {
        font-size: 16px;
        line-height: 170%;
        color: #999999;
        display: block;
        margin-bottom: 13px;
      }

      ul {
        display: flex;

        li {
          margin-right: 11.5px;

          a {
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }

  &-nav {
    padding: 60px 0 63px;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;

    &__col {
      max-width: 215px;
      width: 25%;

      &-title {
        display: block;
        font-size: 24px;
        line-height: 100%;
        text-transform: capitalize;
        color: #222222;
        margin-bottom: 10px;
      }

      ul {
        li {
          margin-bottom: 8px;

          &:last-of-type {
            margin-bottom: 0;
          }

          a {
            color: #222222;
            font-size: 16px;
            line-height: 170%;
            position: relative;
            padding-left: 15px;

            &:before {
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              font-family: "icomoon";
              content: "\e918";
              color: #bbb;
              font-size: 8px;
            }

            @-moz-document url-prefix() {
              &:before {
                top: 60%;
              }
            }
          }
        }
      }

      &:last-of-type {
        margin-right: 50px;

        ul {
          li {
            display: flex;
            color: #222222;
            line-height: 170%;
            margin-bottom: 19px;

            i {
              margin-right: 15px;
              padding-top: 4px;
              font-size: 21px;
            }

            a {
              padding-left: 0;

              &:before {
                display: none;
              }
            }

            .footer-nav__col-phones {
              a {
                display: block;
                color: #222222;
                font-size: 20px;
                margin-bottom: 8px;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }

  &-copy {
    padding-top: 20px;
    padding-bottom: 4px;
    font-size: 14px;
    line-height: 170%;
    color: #999999;
    text-align: center;
  }
}

// media

@media screen and (max-width: 991px) {
  .footer-top__payments {
    padding-right: 0;
  }
  .footer-top__logo {
    margin-left: 0;
  }
  .footer-top__payments ul {
    width: 100%;
    margin-left: -6px;
    margin-right: -6px;
    display: flex;
    flex-wrap: wrap;

    li {
      width: calc(50% - 12px);
      margin: 0 6px;
      margin-bottom: 5px;

      a {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
  }
  .footer-top__payments {
    max-width: 145px;
  }
  .footer-top {
    padding: 40px 0 40px;
  }
  .footer-nav__col:last-of-type {
    margin-right: 0;
  }
  .footer-nav__col:last-of-type ul li .footer-nav__col-phones a {
    font-size: 16px;
  }
  .footer-nav__col:last-of-type ul li i {
    margin-right: 15px;
    padding-top: 6px;
    font-size: 16px;
  }
  .footer-nav {
    padding: 40px 0 40px;
  }
  .footer-nav__col-title {
    font-size: 20px;
  }
  .footer-nav__col ul li a,
  .footer-copy {
    font-size: 14px;
  }
  .footer-nav__col:last-of-type ul li .footer-nav__col-phones a {
    font-size: 14px;
  }

  .footer-nav__col-address {
    font-size: 15px;
  }
}

.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: flex;
  align-content: center;
  border-radius: 10px;
}

.help-popup {
  position: fixed;
  top: 87%;
  left: 93%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  margin-right: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 999; /* Ensure the popup is on top of other elements */
}

.btn-popup {
  position: absolute;
  top: -12px;
  left: -5px;
  background: transparent;
  cursor: pointer;
}

.footer {
  position: relative;
  z-index: 1;
}

.help-popup p {
  margin: 0;
  font-size: large;
  width: max-content;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.help-popup button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  cursor: pointer;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .help-popup {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .footer-top {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }
  .footer-top__logo {
    order: 1;
    margin-top: 0;
  }
  .footer-top__social {
    order: 2;
    margin: 10px 0;
  }
  .footer-top__payments {
    order: 3;
    max-width: 100%;
  }
  .footer-top__social ul {
    justify-content: center;
  }
  .footer-top__payments ul {
    margin-left: 0;
    margin-right: 0;
    justify-content: center;

    li {
      width: auto;
      margin-bottom: 0;
    }
  }
  .footer-nav {
    flex-wrap: wrap;
  }
  .footer-nav__col {
    max-width: 100%;
    width: 45%;
    margin-bottom: 25px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .footer-nav__col:last-of-type ul li {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 374px) {
  .footer-nav__col {
    width: 100%;
  }
  .footer-nav__col-title {
    text-align: center;
    width: 100%;
  }
}

.footer-nav__col ul li {
  display: flex;
  color: #222222;
  line-height: 170%;
  margin-bottom: 19px;
}

.footer-nav__col ul li i {
  margin-right: 15px;
  padding-top: 4px;
  font-size: 21px;
}

.footer-nav__col ul li .footer-nav__col-phones a {
  display: block;
  color: #222222;
  font-size: 20px;
  margin-bottom: 8px;
  line-height: normal;
  padding-left: 0;

  &::before {
    display: none;
  }

  @media (max-width: 991px) {
    font-size: 15px;
  }
}

.footer-nav-mail a {
  padding-left: 0 !important;

  &::before {
    display: none !important;
  }
}
