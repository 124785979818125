body {
  min-height: 100vh;
  overflow: hidden;
}

.video-desc-div {
  background-image: url("https://img.freepik.com/free-photo/kids-home-playing-with-toys_23-2148630637.jpg?t=st=1709528561~exp=1709532161~hmac=7d7ba4dfc77f772d8604ea78d0e7805c63dea34f85bcd04a8b033faf3821edb8&w=2000");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  color: #fff;
  width: 50%;
  height: 600px;
}
.video-div {
  width: 50%;
}
.starRating {
  font-size: 24px;
  cursor: pointer;
  margin: 10px 0;
  gap: 10px;
  display: flex;
  justify-content: space-between;
}
.ratingBar {
  font-family: Arial, sans-serif;
}
.review-submit-btn {
  background: pink;
  border-radius: 10px;
  width: 100px;
  color: rgb(28, 11, 11);
  font-size: 15px;
  font-weight: bold;
  margin: 10px 0;
  border: none;
  padding: 10px;
}
.ratingValue {
  font-size: 2em;
  color: #4caf50; /* Adjust the color to match your design */
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 1rem 0;
}

.ratingRow {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.ratingLabel {
  flex-shrink: 0;
  width: 50px;
}

.ratingProgressContainer {
  flex-grow: 1;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin: 0 10px;
  height: 12px;
}

.ratingProgress {
  height: 12px;
  border-radius: 5px;
}

.ratingCount {
  flex-shrink: 0;
  width: 50px;
  text-align: right;
}

.star {
  cursor: pointer;
}

.star:hover,
.star.active {
  color: #ffa41c;
}
.review-card-card {
  border-radius: 4px;
  padding: 5px;
  margin: 1px;
}

.review-card-shop {
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px;
}

.review-card-rating {
  font-size: 16px;
  font-weight: bold;
  background-color: #ffa41c;
  border-radius: 5px;
  width: 40px;
  padding: 5px;
  gap: 5px;
  display: flex;
}
.rating-main-head {
  text-align: center;
  font-family: Tenor Sans;
  color: orange;
  font-size: 54px;
  margin: 3rem 0;
}

.review-card-title {
  font-weight: bold;
  margin-top: 8px;
}

.review-card-content {
  margin-top: 8px;
}

.review-card-footer {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review-card-reviewerName {
  font-family: "Itim";
  display: flex;
  gap: 5px;
  text-transform: capitalize;
}

.review-card-verified {
  color: green;
  font-weight: bold;
}

.review-card-date {
  color: #666;
}
.videoContainer {
  position: relative;
  width: 80%; /* Adjust based on your requirements */
  height: 700px;
  margin: 1rem auto;
  border: 9px solid pink;
  border-radius: 1rem;
}

.video-tag {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.blog-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.blog-square:hover {
  -webkit-transform: translate(20px, -10px);
  -ms-transform: translate(10px, -10px);
  transform: translate(10px, -10px);
  -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
}

.blog-square {
  width: 420px;
  height: fit-content;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 20px 50px #d9dbdf;
  padding: 10px;
  text-align: left;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.blog-mask {
  clip: rect(0px, 460px, 220px, 0px);
  border-radius: 4px;
  position: absolute;
}

.blog-img {
  width: 460px;
}

.blog-h1 {
  margin: auto;
  text-align: left;
  margin-top: 240px;

  font-size: 20px;
}
.blog-p {
  text-align: justify;
  padding-left: 30px;
  padding-right: 30px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: #c8c8c8;
  line-height: 18px;
}

.blog-button {
  background-color: pink;
  color: rgb(30, 30, 30);
  width: 100px;
  border: none;
  font-weight: 600;

  padding: 10px 18px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: block;

  margin: 10px;
  font-size: 12px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .video-tag {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .videoContainer {
    height: 240px;
  }
  .blog-square {
    width: fit-content;
    height: fit-content;
  }
}

.muteButton {
  position: absolute;
  top: 90%;
  left: 90%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .muteButton {
  }
  .muteButton span {
    display: none;
  }
}

.muteButton:hover {
  opacity: 1;
}
.review-rating-div {
  width: 70vw;
  border: 2px solid gray;
  height: 70vh;
  margin: 3rem auto;
  display: flex;
  border-radius: 5px;
}
.review-card-div {
  width: 50%;
  overflow-y: scroll;
}
.rating-card-div {
  width: 50%;
  margin: 2rem;
}

.customCheckboxLabel {
  position: absolute;
  z-index: 10;
  left: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid #000;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customCheckboxLabel.checked {
  background-color: #ffa41c;
}

.customCheckboxLabel.checked::before {
  content: "✓";
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.basic-btn {
  color: white;
  padding: 0.75rem 2.5rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1.2rem;
}

.btn-intermediate {
  background: linear-gradient(to right, #ff416c, #ff4b2b);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-intermediate:hover {
  transform: scale(1.05);
}

.basic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.basic-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  margin: 20px;
  padding: 25px;
}
.basic-card-header {
  font-size: 28px;
  color: #333;
  text-align: center;
  position: relative;
  padding-bottom: 20px;
  font-weight: bold;
}
.basic-card-header::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  height: 4px;
  width: 60px;
  background-color: #007bff;
  border-radius: 2px;
}
.basic-feature-list {
  list-style: none;
  padding: 0;
}
.basic-feature-list li {
  margin-bottom: 15px;
  line-height: 1.6;
  font-size: 16px;
}
.basic-feature-list li::before {
  content: "✔";
  color: #28a745;
  font-weight: bold;
  display: inline-block;
  width: 25px;
}
.basic-important {
  font-weight: bold;
  color: #007bff;
}
.basic-cta-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}
.basic-cta-button:hover {
  background-color: #0056b3;
}
@media (max-width: 768px) {
  .basic-container {
    padding: 10px;
  }
}

/* styles/Modal.module.css */
/* styles/Modal.module.css */
.modal {
  display: flex;
  position: absolute; /* Changed from fixed to absolute */
  z-index: 10; /* Ensure it is above all other content */
  left: 0;
  top: -10%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: center;
}

.modalContent {
  position: relative; /* Ensure the close icon positions itself relative to this container */
  background-color: #fefefe;
  padding: 20px;
  border: 5px solid #888;
  width: 25%; /* Adjust width as needed */
  height: 48%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for better visibility */
}

.close {
  color: #aaa;
  position: absolute; /* Ensure it is right aligned properly */
  right: 10px; /* Distance from the right, adjust as needed */
  top: 10px; /* Distance from the top, adjust as needed */
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: black;
}

@media screen and (max-width: 768px) {
  .modalContent {
    width: 80%;
    height: auto;
  }
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");

.sub-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  height: 60vh;
  line-height: 1.8;
  font-family: "Open Sans", sans-serif;
}

.card-basic,
.card-premium,
.card-standard {
  margin: 0 2rem 1rem 0;
  padding: 0 0 2rem 0;
  width: 25rem;
  height: 25rem;
  background: #fff;
  color: #444;
  text-align: center;
  border-radius: 1rem;
  box-shadow: 0.5rem 0.5rem 1rem rgba(51, 51, 51, 0.2);
  overflow: hidden;
  transition: all 0.1ms ease-in-out;
}
.card-basic:hover,
.card-premium:hover,
.card-standard:hover {
  transform: scale(1.02);
}

.card-header {
  height: 5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
  padding: 1rem 0;
  color: #fff;
  clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
}

.header-basic,
.sub-btn-basic {
  background: linear-gradient(135deg, rgb(0, 119, 238), #06c766);
}

.header-standard,
.sub-btn-standard {
  background: linear-gradient(135deg, #c878d2, #de2892);
}

.header-premium,
.sub-btn-premium {
  background: linear-gradient(135deg, #eea300, #ee5700);
  color: #fff !important;
}

.card-body {
  padding: 0.5rem 0;
}
.card-body h2 {
  font-size: 2rem;
  font-weight: 500;
}

.card-element-container {
  color: #444;
  list-style: none;
  text-align: center;
}

.sub-btn {
  margin: 0.5rem 0;
  padding: 0.7rem 1rem;
  outline: none;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 700;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.1ms ease-in-out;
}

.sub-btn:hover {
  transform: scale(0.95);
}

.sub-btn:active {
  transform: scale(1);
}

/* Base styles */
.sub-main {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  min-height: 60vh; /* Changed from height to min-height */
  line-height: 1.8;
  font-family: "Open Sans", sans-serif;
  overflow-y: scroll;
}

/* Card Styles */
.card-basic,
.card-premium,
.card-standard {
  margin: 1rem; /* Adjusted for consistency */
  width: 100%; /* Start with full width on small screens */
  max-width: 25rem; /* Maximum width to maintain on larger screens */
  height: auto; /* Adjust height automatically */
  /* Other styles remain unchanged */
}

/* Responsive Media Queries */
@media (min-width: 768px) {
  /* Adjustments for tablets and above */
  .sub-main {
    justify-content: space-evenly; /* Adjust layout for wider screens */
  }
}

@media (min-width: 1024px) {
  /* Adjustments for desktops and above */
  .card-basic,
  .card-premium,
  .card-standard {
    width: calc(
      33.333% - 2rem
    ); /* Three items per row - adjust the calculation based on your margins */
  }
}

/* Button and Text Sizes */
.card-body h2,
.sub-btn {
  font-size: 1rem; /* Adjust base font size for better mobile readability */
}

@media (min-width: 768px) {
  .card-body h2 {
    font-size: 1.5rem; /* Larger text for tablets and above */
  }

  .sub-btn {
    font-size: 0.9rem; /* Slightly larger buttons on larger screens */
  }
}

.card-element-hidden {
  display: none;
}

.media-news {
  color: #ffa41c;
  text-align: center;

  margin: 1rem;
}

.target {
  animation-duration: 3s;
  animation-name: slidein;
  margin-top: 1rem;
}
.news-header {
  display: flex;
  justify-content: space-evenly;
  margin: 1rem auto;
}
.news-description {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 50vh;
  width: 40vw;
  margin-top: 2rem;
}
@media screen and (max-width: 768px) {
  .news-header {
    flex-direction: column;
  }
  .news-description {
    width: 100vw;
    padding: 2rem;
    margin: 2rem;
  }
}

@keyframes slidein {
  from {
    margin-left: -60%;
    width: 300%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
}

.target-news {
  animation-duration: 3s;
  animation-name: slidein;
  margin-top: 1rem;
  color: #ffa41c;
  font-family: Itim;
}

@keyframes slidein {
  from {
    margin-left: 60%;
    width: 300%;
  }
  to {
    margin-left: 190%;
    width: 100%;
  }
}

.target-span {
  animation-duration: 3s;
  animation-name: slidein;
  margin-top: 1rem;
  color: #ffa41c;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

  font-size: 18px;
}

@keyframes slidein {
  from {
    margin-left: -190%;
    width: 300%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .video-home-div {
    flex-direction: column;
  }
  .video-desc-div {
    width: 100vw;
  }
  .video-div {
    width: 100%;
  }
  .videoContainer {
    width: 100%;
  }
  .review-rating-div {
    flex-direction: column;
    width: 100vw;
  }
  .review-card-div {
    width: 100%;
  }
  .rating-card-div {
    width: 100%;
  }
}
/* 
.j{
	color: #c9089c;
  font-size: 2.5rem;
  font-weight: bolder;
}
.u{
	color: yellow;
  font-size: 2.5rem;
  font-weight: bolder;
}
.s{
	color:blue;
  font-size: 2.5rem;
  font-weight: bolder;
}
.p{
	color:red;
  font-size: 2.5rem;
  font-weight: bolder;
}
.l{
	color:#08bdc9;
  font-size: 2.5rem;
  font-weight: bolder;
}
.a{
	color: #c9089c;
  font-size: 2.5rem;
  font-weight: bolder;
}
.y{
	color:#2c9415;
  font-size: 2.5rem;
  font-weight: bolder;
}
.t{
	color:red;
  font-size: 2.5rem;
  font-weight: bolder;
}
.o{
	color:yellow;
  font-size: 2.5rem;
  font-weight: bolder;
}

* {
  margin: 0;
  padding: 0;
  transition: 0.7s ease-in-out all;
}

.heading {
  font-family: "eight", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: larger;
}

.large-text {
  font-size: 5rem;
}
.half-circle {
  width: 350px;
  height: 130px;
  border-radius: 400px 400px 0 0;
  margin: 30px;
}
.stroke-alt {
  border-top: 15px solid purple;
  border-left: 20px solid purple;
  border-right: 20px solid purple;
  margin-left: 550px;
}


.lower{
    width: 600px;
   height: 100px;
   border: solid 20px #34244d;
  border-color: transparent transparent #34244d transparent;
  border-radius: 0 0 240px 50%/60px;
	margin-left:200px;
	padding-top:100px;
}

#hero {
  background: #101010;
  color: #fff;
  padding: 2rem;
}

#hero::after {
  content: '';
  width: 100vw;
  height: 5rem;
  background: rgb(16,20,118);
  background: linear-gradient(2deg, rgba(16,20,118,1) 0%, rgba(16,20,118,1) 50%, rgba(16,16,16,1) 50%, rgba(16,16,16,1) 100%); 
  display: block;
  position: absolute;
  left: 0;

}

#loading-animation {
  position: absolute;
  width: 100vw;
  height: 100vh;
  text-align: center;
  padding-top: 5rem;
  color: #fff;
}
#loading-animation2 {
  
  width: 100vw;
  height: 100vh;
  text-align: center;
  padding-top: 55vh;
  color: #fff;
}

#loading-animation p {
  display: inline-block;
  animation: bounce 1s infinite ease-in-out;
}
#loading-animation2 p {
  display: inline-block;
  animation: bounce 1s infinite ease-in-out;
}

#loading-animation p:nth-of-type(2) {
  animation-delay: 0.6s
}

#loading-animation p:nth-of-type(3) {
  animation-delay: 0.5s
}

#loading-animation p:nth-of-type(4) {
  animation-delay: 0.6s
}

#loading-animation p:nth-of-type(5) {
  animation-delay: 0.4s
}

#loading-animation p:nth-of-type(6) {
  animation-delay: 0.4s
}

#loading-animation p:nth-of-type(7) {
  animation-delay: 0.6s
}
#loading-animation2 p:nth-of-type(1){
	animation-delay:0.5s;
}
#loading-animation2 p:nth-of-type(2){
	animation-delay:0.4s;
}
#loading-animation2 p:nth-of-type(3){
	animation-delay:0.5s;
}
#loading-animation2 p:nth-of-type(4){
	animation-delay:0.4s;
}

@keyframes bounce {
  30% {
    transform: translateY(-50px);
  }
  60% {
    transform: none;
  }
}  */
:root {
  --color-1: #f23492;
  --color-3: #3e60b5;
  --color-4: #fe293f;
  --color-5: #62c2fe;
  --color-2: yellow;
  --color-6: #27c653;
  --accent-color: #3f3440;
  --body-color: #393937;
  --white-color: #fff;
  --box-shadow: 0 0 5px #b3e6e8, 0 0 25px #bde3e4, 0 0 50px #b3dedf,
    0 0 100px #86a1a3;
}

.button-animation {
  position: relative;
  padding: 20px 30px;
  color: var(--accent-color);
  text-transform: uppercase;
  overflow: hidden;
  letter-spacing: 4px;
  transition: 0.5s;
  border: none;
  cursor: pointer;
  background: none;
}

.button-animation span {
  position: absolute;
  display: block;
  background: linear-gradient(90deg, transparent, var(--accent-color));
}

.button-animation span:nth-child(1),
.button-animation span:nth-child(3) {
  width: 100%;
  height: 2px;
}

.button-animation span:nth-child(2),
.button-animation span:nth-child(4) {
  width: 2px;
  height: 100%;
}

.button-animation span:nth-child(1) {
  top: 0;
  left: -100%;
  animation: btn-anim1 1s linear infinite;
}

.button-animation span:nth-child(2) {
  top: -100%;
  right: 0;
  animation: btn-anim2 1s linear infinite 0.75s;
}

.button-animation span:nth-child(3) {
  bottom: 0;
  right: -100%;
  animation: btn-anim3 1s linear infinite 0.75s;
}

.button-animation span:nth-child(4) {
  bottom: -100%;
  left: 0;
  animation: btn-anim4 1s linear infinite 0.75s;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

.button-animation:hover {
  background-color: var(--accent-color);
  color: var(--white-color);
  border-radius: 5px;
  box-shadow: var(--box-shadow);
}

/* Keyframes */
@keyframes blink {
  0% {
    opacity: 0.1;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

/* Styles */
.main {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9fbfe;
}

.dank-ass-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dank-ass-loader .row {
  display: flex;
}

.arrow {
  width: 0;
  height: 0;
  margin: 0 (-12px / 2);
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: calc(12px * 1.8) solid var(--color-1); /* Use the first color initially */
  animation: blink 1s infinite;
  filter: drop-shadow(
    0 0 (12px * 1.5) var(--color-1)
  ); /* Use the first color initially */
}

.arrow.down {
  transform: rotate(180deg);
}

.arrow.outer-1 {
  border-bottom-color: var(--color-1);
  filter: drop-shadow(0 0 (12px * 1.5) var(--color-1));
  animation-delay: -0.0555556s;
}

.arrow.outer-2 {
  border-bottom-color: var(--color-2);
  filter: drop-shadow(0 0 (12px * 1.5) var(--color-2));
  animation-delay: -0.166667s;
}

.arrow.outer-3 {
  border-bottom-color: var(--color-3);
  filter: drop-shadow(0 0 (12px * 1.5) var(--color-3));
  animation-delay: -0.277778s;
}

.arrow.outer-4 {
  border-bottom-color: var(--color-4);
  filter: drop-shadow(0 0 (12px * 1.5) var(--color-4));
  animation-delay: -0.388889s;
}

.arrow.outer-5 {
  border-bottom-color: var(--color-5);
  filter: drop-shadow(0 0 (12px * 1.5) var(--color-5));
  animation-delay: -0.5s;
}

.arrow.outer-6 {
  border-bottom-color: var(--color-6);
  filter: drop-shadow(0 0 (12px * 1.5) var(--color-6));
  animation-delay: -0.611111s;
}

.para {
  font-size: 22px;
  text-align: center;
  margin-top: 20px;
  font-family: Itim, san-serif;
}

.para .span-1 {
  color: var(--color-1);
  transform: translateY(-150px);
}

.para .span-2 {
  color: var(--color-2);
}

.para .span-3 {
  color: var(--color-3);
}

.para .span-4 {
  color: var(--color-4);
}
.para .span-5 {
  color: var(--color-5);
  margin: 0 9px;
}

.para .span-6 {
  color: var(--color-1);
  margin: 0 5px;
}

.para .span-7 {
  color: var(--color-6);
  margin: 0 5px;
}

@media screen and (max-width: 768px) {
  .blog-para {
  }
  .blog-card {
    height: 50px;
  }
  .blog-content {
  }
}

.container {
  width: 520px;
  height: 520px;
  top: 80%;
  left: 60%;

  padding: 1rem;
  transform: translate(35vw, 50%);
}

.custom-modal {
  display: flex;
  align-items: center;
  height: 60%;
  justify-content: center;
}

.custom-modal-content {
  width: 50%; /* or any other size */
  margin: auto; /* this will help center the content */
}

@media screen and (max-width: 768px) {
  .custom-modal-content {
    width: 80%;
  }
  .custom-modal {
    height: 50%;
  }
}

.head {
  background: #fff;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 1rem;
  font-family: "Titan One", cursive;
  font-size: 90px;
  font-weight: 700;
  flex-flow: row wrap;
  align-content: center;
  justify-content: center;
}

.head span {
  width: 100%;
  position: relative;
}

.head span:before {
  background: linear-gradient(65deg, #2cdf47, #e0f42c, #f31b46, blue);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  content: "";
  mix-blend-mode: screen;
}

[class*="blobs"] {
  mix-blend-mode: color;
  animation: blobs 3s ease-in-out infinite alternate;
}

.blobs_1 {
  background: #ff1493;
  width: 80px;
  height: 80px;
  top: 90px;
  left: 210px;
  display: block;
  position: absolute;
}

.blobs_2 {
  background: #ff4500;
  width: 100px;
  height: 100px;
  top: 155px;
  left: 230px;
  display: block;
  position: absolute;
}

.blobs_3 {
  background: #00ff00;
  width: 80px;
  height: 80px;
  top: 145px;
  left: 20px;
  display: block;
  position: absolute;
}

.blobs_4 {
  background: #ff0000;
  width: 120px;
  height: 120px;
  top: 115px;
  left: 100px;
  display: block;
  position: absolute;
}

.blobs_5 {
  background: #ffff00;
  width: 90px;
  height: 90px;
  top: 55px;
  left: 70px;
  display: block;
  position: absolute;
}

.blobs_6 {
  background: #00ffff;
  width: 80px;
  height: 80px;
  top: 220px;
  left: 100px;
  display: block;
  position: absolute;
}

.blobs_7 {
  background: #ff8c00;
  width: 100px;
  height: 100px;
  top: 80px;
  left: 60px;
  display: block;
  position: absolute;
}
.blobs_8 {
  background: #0080ff;
  width: 100px;

  height: 100px;
  top: 210px;
  left: 200px;
  display: block;
  position: absolute;
}
.blobs_9 {
  background: #00ff33;
  width: 100px;
  height: 100px;
  top: 210px;
  left: 200px;
  display: block;
  position: absolute;
}
.blobs_10 {
  background: #ff0095;
  width: 100px;
  height: 100px;
  top: 80px;
  left: 20px;
  display: block;
  position: absolute;
}
.blobs_11 {
  background: #88ff00;
  width: 100px;
  height: 100px;
  top: 50px;
  left: 50px;
  display: block;
  position: absolute;
}

@keyframes blobs {
  0% {
    border-radius: 26% 74% 61% 39% / 54% 67% 33% 46%;
  }
  10% {
    border-radius: 74% 26% 47% 53% / 68% 46% 54% 32%;
  }
  20% {
    border-radius: 48% 52% 30% 70% / 27% 37% 63% 73%;
  }
  30% {
    border-radius: 73% 27% 57% 43% / 28% 67% 33% 72%;
  }
  40% {
    border-radius: 63% 37% 56% 44% / 25% 28% 72% 75%;
  }
  50% {
    border-radius: 39% 61% 70% 30% / 61% 29% 71% 39%;
  }
  60% {
    border-radius: 27% 73% 29% 71% / 73% 51% 49% 27%;
  }
  70% {
    border-radius: 39% 61% 65% 35% / 74% 65% 35% 26%;
  }
  80% {
    border-radius: 55% 45% 37% 63% / 38% 30% 70% 62%;
  }
  90% {
    border-radius: 25% 75% 70% 30% / 39% 50% 50% 61%;
  }
  100% {
    border-radius: 66% 34% 33% 67% / 65% 73% 27% 35%;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap");
.about-image {
  height: 300px;
  object-fit: cover;
}
.about-div {
  display: flex;
  flex-direction: column;
  text-align: start;
}
.about-head {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}
.about-desc {
  color: #fff;
  margin-bottom: 10px;
}
.about-section {
  display: flex;
  flex-direction: column;
  width: 42vw;
  height: 300px;

  border-radius: 10px;
  position: relative;
  margin: 15px auto;
}
.about-review-div {
  display: flex;
  flex-wrap: wrap;
}
.about-footer {
  display: flex;
  flex-direction: column;
  background-color: #7c5594;
  height: 120px;
  position: absolute;
  width: 42vw;
  justify-content: center;
  align-items: center;
  bottom: 0;
  border-radius: 10px;
  border: 1px solid #fff;
}

.about-btn {
  width: 100px;
  margin-left: 0;
  display: inline-block;
  padding: 10px 20px;
  background: linear-gradient(
    to right,
    #ab357a,
    #955392
  ); /* Adjust colors as needed */
  color: #fff; /* Text color */
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 4px; /* Rounded corners */
  cursor: pointer;
  transition: background 0.3s; /* Smooth hover effect */
  font-weight: bold;
}
.about-review {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 15px;
}
.about-why-us {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px auto;
}
.about-why-us-desc {
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: 10px;
  border-radius: 10px;
  padding: 10px;
}
.about-make-special-desc {
  display: flex;
  width: 600px;
  margin: 10px;
  border-radius: 10px;
  padding: 10px;
}
@media screen and (max-width: 768px) {
  .about-make-special-desc {
    flex-direction: column;
  }
}
.details {
  font-size: 1rem;
  color: hsl(0deg 0% 25%);
  margin: 32px;
  border-radius: 4px;
  border: 1px solid pink;
}
.embrace-toy-rental-img {
  width: 120px;
  margin: 0.5rem;
}
@media screen and (max-width: 768px) {
  .details {
    width: 80vw;
  }
  .embrace-toy-rental-img {
    width: 300px;
  }
}

.summary:hover {
  background-color: pink;
  color: #fff;
  font-weight: bold;
}
.summary {
  cursor: pointer;
  padding: 12px 16px;
  outline-offset: 5px;
}
.faq-div {
  background: #fcedea;
  border-radius: 0 0 4px 4px;
  padding: 12px 17px;
  font-style: italic;
  color: #555;
  font-weight: 500;
  line-height: 30px;
}
.faq-container {
  width: 60vw;
}
@media screen and (min-width: 768px) {
  .faq-container {
    margin: 30px auto;
  }
}
.about-hero {
  display: flex;
  padding: 20px;
  /* background-color: #fcedea; */
  margin: 20px 19px;
  border-radius: 10px;
}

.about-hero-img {
  width: 45vw;
  border-radius: 10px;
  margin-top: 5rem;
}
.about-hero-desc {
  width: 50vw;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .about-hero {
    flex-direction: column;
  }
  .about-hero-img {
    width: 90vw;
  }
  .about-hero-desc {
    width: 90vw;
  }
}
@media screen and (max-width) {
  .redeem-container {
    width: 90vw;
  }
}
@media screen and (min-width: 1020px) {
  .redeem-container {
    width: 44vw;
  }
}
@media screen and (width: 1280px) {
  .redeem-container {
    width: 43vw;
  }
}

.points {
  font-size: 16px;
  font-weight: 600;
  margin-top: 3px;
}
.disclaimer {
  font-size: 14px;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
}
.gradient-button {
  display: inline-block;
  padding: 10px 20px;
  background: linear-gradient(
    to right,
    #ab357a,
    #955392
  ); /* Adjust colors as needed */
  color: #fff; /* Text color */
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 4px; /* Rounded corners */
  cursor: pointer;
  transition: background 0.3s; /* Smooth hover effect */
  font-weight: bold;
  margin-left: 15px;
}
.gradient-button:hover {
  background: linear-gradient(
    to right,
    #ff6b6b,
    #ff7f50
  ); /* Adjust hover colors as needed */
}

.search-items,
body {
  overflow-y: scroll;
}

.OtpInputStyle,
.gallery-post,
.message,
.message__line {
  text-align: center;
}

:root {
  --swiper-pagination-color: var(--swiper-theme-color);
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}
/*body::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/

.header-items {
  display: flex;
  width: 170px;
}
@media screen and (width: 768px) {
  .header-items {
    width: 170px;
  }
}
.search-items {
  position: absolute;
  width: 60vw;
  background-color: #fff;
  max-height: 400px;
  margin-top: 30px;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.carousel-card-testimonial {
  height: auto;
  width: 70vw;
  border-radius: 10px;
  padding: 10px;
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fcedea;
  font-family: "Nunito", sans-serif;
}
@media screen and (max-width: 768px) {
  .carousel-card-testimonial {
    flex-direction: column;
    width: 85vw;
  }
}
.carousel-card {
  height: auto;
  width: 70vw;
  border-radius: 10px;
  padding: 10px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  background-color: #fcedea;
  font-family: "Nunito", sans-serif;
}
@media screen and (max-width: 768px) {
  .carousel-card {
    width: 85vw;
  }
}
.carousel-card-img-testimonial {
  height: 280px;
  width: 280px;
  object-fit: cover;
  object-position: top;
  margin: 10px;
  border-radius: 10px;
  background-position: top;
}
.aboutCarousel {
  margin: 2rem auto;
  max-width: 800px;
}
.swiper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.swiper-pagination-bullet-active {
  background: #007aff !important;
}

.carousel-card-img {
  height: 220px;
  width: 220px;
  object-fit: cover;
  object-position: top;
  margin: 7px auto;
  border-radius: 200px;
  background-position: top;
}
.carouselCardTestimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding: 1rem;
}

.carouselCardImgTestimonial {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.carouselCardTestimonialContent {
  display: flex;
  flex-direction: column;
  min-height: 230px;
  margin: 1.7rem;
  justify-content: space-between;
}

.carouselCardTestimonialName {
  font-family: "Nunito, sans-serif";
  margin-bottom: 10px;
}

.carouselCardTestimonialDetail {
  font-family: "Nunito, sans-serif";
}

/* carousel */
.carousel {
  height: 70vh;
  margin-top: -50px;
  width: 90vw;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  margin: 0 auto;
}
.testimonial-img {
  height: 100%;
  width: 100%;
  background-image: url("https://main-object-storage.blr1.cdn.digitaloceanspaces.com/20231224_150352.jpg");
  background-position: 20% 26%;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .carousel {
    height: 98vh;
  }
}
.carousel .list .item {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0 0 0 0;
}
.carousel .list .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carousel .list .item .content {
  position: absolute;
  top: 20%;
  width: 1140px;
  max-width: 80%;
  left: 50%;
  transform: translateX(-50%);
  padding-right: 30%;
  box-sizing: border-box;
  color: #fff;
  text-shadow: 0 5px 10px #0004;
}
.carousel .list .item .author {
  font-weight: bold;
  letter-spacing: 10px;
}
.author {
  font-size: larger;
}
.carousel .list .item .title,
.carousel .list .item .topic {
  font-size: 2.5em;
  font-weight: bold;
  line-height: 1.3em;
}
.carousel .list .item .topic {
  color: #f1683a;
}
.carousel .list .item .buttons {
  display: grid;
  grid-template-columns: repeat(2, 130px);
  grid-template-rows: 40px;
  gap: 5px;
  margin-top: 20px;
}
.carousel .list .item .buttons button {
  border: none;
  background-color: #eee;
  letter-spacing: 3px;
  font-family: Poppins;
  font-weight: 500;
}
.carousel .list .item .buttons button:nth-child(2) {
  background-color: transparent;
  border: 1px solid #fff;
  color: #eee;
}
/* thumbail */
.thumbnail {
  position: absolute;
  bottom: 50px;
  left: 50%;
  width: max-content;
  z-index: 100;
  display: flex;
  gap: 20px;
}
.thumbnail .item {
  width: 140px;
  height: 160px;
  flex-shrink: 0;
  position: relative;
}
.thumbnail .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.thumbnail .item .content {
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
}
.thumbnail .item .content .title {
  font-weight: 500;
}
.thumbnail .item .content .description {
  font-weight: 300;
}
/* arrows */
.arrows {
  position: absolute;
  top: 80%;
  right: 52%;
  z-index: 100;
  width: 300px;
  max-width: 30%;
  display: flex;
  gap: 10px;
  align-items: center;
}
.arrows button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #eee4;
  border: none;
  color: #fff;
  font-family: monospace;
  font-weight: bold;
  transition: 0.5s;
}
.arrows button:hover {
  background-color: #fff;
  color: #000;
}

/* animation */
.carousel .list .item:nth-child(1) {
  z-index: 1;
}
.first-block {
  background-color: #fff;
  border-radius: 10px;
  color: #000;
  padding: 1rem;
  margin-bottom: 2rem;
}

/* animation text in first item */

.carousel .list .item:nth-child(1) .content .author,
.carousel .list .item:nth-child(1) .content .first-block,
.carousel .list .item:nth-child(1) .content .title,
.carousel .list .item:nth-child(1) .content .topic,
.carousel .list .item:nth-child(1) .content .des,
.carousel .list .item:nth-child(1) .content .buttons {
  transform: translateY(50px);
  filter: blur(20px);
  opacity: 0;
  animation: showContent 0.5s 1s linear 1 forwards;
}
@keyframes showContent {
  to {
    transform: translateY(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
.carousel .list .item:nth-child(1) .content .title {
  animation-delay: 1.2s !important;
}
.carousel .list .item:nth-child(1) .content .topic {
  animation-delay: 1.4s !important;
}
.carousel .list .item:nth-child(1) .content .des {
  animation-delay: 1.6s !important;
}
.carousel .list .item:nth-child(1) .content .buttons {
  animation-delay: 1.8s !important;
}
/* create animation when next click */
.carousel.next .list .item:nth-child(1) img {
  width: 150px;
  height: 220px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  border-radius: 30px;
  animation: showImage 0.5s linear 1 forwards;
}
@keyframes showImage {
  to {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.carousel.next .thumbnail .item:nth-last-child(1) {
  overflow: hidden;
  animation: showThumbnail 0.5s linear 1 forwards;
}
.carousel.prev .list .item img {
  z-index: 100;
}
@keyframes showThumbnail {
  from {
    width: 0;
    opacity: 0;
  }
}
.carousel.next .thumbnail {
  animation: effectNext 0.5s linear 1 forwards;
}

@keyframes effectNext {
  from {
    transform: translateX(150px);
  }
}

.des {
  background-color: #fff;
  color: #000;
  padding: 0.9rem;
  border-radius: 10px;
  line-height: 1.4rem;
  margin-top: -1rem;
}
/* running time */

.carousel .time {
  position: absolute;
  z-index: 1000;
  width: 0%;
  height: 3px;
  background-color: #f1683a;
  left: 0;
  top: 0;
}

.carousel.next .time,
.carousel.prev .time {
  animation: runningTime 3s linear 1 forwards;
}
@keyframes runningTime {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

/* prev click */

.carousel.prev .list .item:nth-child(2) {
  z-index: 2;
}

.carousel.prev .list .item:nth-child(2) img {
  animation: outFrame 0.5s linear 1 forwards;
  position: absolute;
  bottom: 0;
  left: 0;
}
@keyframes outFrame {
  to {
    width: 150px;
    height: 220px;
    bottom: 50px;
    left: 50%;
    border-radius: 20px;
  }
}

.carousel.prev .thumbnail .item:nth-child(1) {
  overflow: hidden;
  opacity: 0;
  animation: showThumbnail 0.5s linear 1 forwards;
}
.carousel.next .arrows button,
.carousel.prev .arrows button {
  pointer-events: none;
}
.carousel.prev .list .item:nth-child(2) .content .author,
.carousel.prev .list .item:nth-child(2) .content .title,
.carousel.prev .list .item:nth-child(2) .content .topic,
.carousel.prev .list .item:nth-child(2) .content .des,
.carousel.prev .list .item:nth-child(2) .content .buttons {
  animation: contentOut 1.5s linear 1 forwards !important;
}

@keyframes contentOut {
  to {
    transform: translateY(-150px);
    filter: blur(20px);
    opacity: 0;
  }
}
@media screen and (max-width: 678px) {
  .carousel .list .item .content {
    padding-right: 0;
  }
  .carousel .list .item .content .title {
    font-size: 30px;
  }
}
.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 40px 15px;
}

.timeline-container::after {
  background-color: #e17b77;
  content: "";
  position: absolute;
  left: calc(50% - 2px);
  width: 4px;
  height: 100%;
}

.timeline-item {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  position: relative;
  margin: 10px 0;
  width: 50%;
}

.timeline-item:nth-child(odd) {
  align-self: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 0;
}

.timeline-item-content {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  position: relative;
  width: 400px;
  max-width: 70%;
  text-align: right;
}

.timeline-item-content::after {
  content: " ";
  background-color: #fff;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -7.5px;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
}

.timeline-item:nth-child(odd) .timeline-item-content {
  text-align: left;
  align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-item-content .tag {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
  left: 5px;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
}

.timeline-item:nth-child(odd) .timeline-item-content .tag {
  left: auto;
  right: 5px;
}

.timeline-item-content,
time {
  color: #777;
  font-size: 12px;
  font-weight: bold;
}

.timeline-item-content p {
  font-size: 16px;
  line-height: 24px;
  margin: 15px 0;
  max-width: 250px;
}

.timeline-item-content a {
  color: #333;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}

.timeline-item-content a::after {
  content: " ►";
  font-size: 12px;
}

.timeline-item-content .circle {
  background-color: #fff;
  border: 3px solid #e17b77;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: -40px;
  width: 20px;
  height: 20px;
  z-index: 100;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
  right: auto;
  left: -40px;
}

@media screen and (max-width: 1023px) {
  .timeline-item-content {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .timeline-item-content,
  .timeline-item:nth-child(odd) .timeline-item-content {
    padding: 15px 10px;
    text-align: center;
    align-items: center;
  }

  .timeline-item-content .tag {
    width: calc(100% - 10px);
    text-align: center;
  }

  .timeline-item-content time {
    margin-top: 20px;
  }

  .timeline-item-content a {
    text-decoration: underline;
  }

  .timeline-item-content a::after {
    display: none;
  }
}
.about-banner {
  display: flex;
  background-color: #fcedea;
  justify-content: space-evenly;
  margin: 20px auto;
  width: 98vw;
  border-radius: 10px;
}
.about-banner-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 210px;
  margin: auto 20px;
  width: 200px;
  align-items: center;
}
.happy-to-help {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .about-banner {
    flex-direction: column;
  }
  .content {
    margin-top: -5rem;
  }
  .about-banner {
    margin: 10px auto;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
  }
  .about-banner-card {
    width: 43%;
    align-items: center;
  }
  .happy-to-help {
    display: none;
  }
}
.about-carousel {
  width: 100vw;
}

@media screen and (min-width: 769px) {
  .about-banner-card {
  }
}

.about-banner-btn {
  border-radius: 50%;
  height: 68px;
  width: 70px;
  border: none;
  font-size: 30px;
}
.about-banner-btn:hover {
  background-color: palevioletred;
  color: #fff;
}

.blog-card {
  position: relative;
  width: 400px;
  height: 260px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  padding: 15px 30px;
  background: #fff;
  cursor: pointer;
  margin: 40px auto;
}
@media screen and (max-width: 768px) {
}
.blog-card:hover {
  height: 420px;
}

.blog-imgbox {
  position: relative;
  width: 100%;
  height: 80%;
  transform: translateY(-70px);
  z-index: 99;
}

.blog-img {
  width: 300px;
  height: 200px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
}

.blog-content {
  padding: 15px 10px;
  text-align: center;
  transform: translateY(-350px);
  opacity: 0;
  transition: 0.5s;
  margin-top: 4.8rem;
}

.blog-card:hover > .blog-content {
  opacity: 1;
  transform: translateY(-180px);
}

.blog-content p {
  font-size: 18px;
}
.blog-imgbox h4 {
  color: #7f9ead;
  margin-top: 20px;
  width: 250px;
}
.blogs-div {
  display: flex;
  margin: 10px auto;

  width: 90vw;
  flex-wrap: wrap;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.happy-to-help {
  animation: fadeIn 1s ease-out;
}

.fade-out {
  animation: fadeOut 1s ease-out forwards; /* 'forwards' keeps the element in the final state after animation ends */
}

/* footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

footer p {
    margin: 10px 0;
}

footer i {
    color: red;
}

footer a {
    color: #3c97bf;
    text-decoration: none;
} */
@media screen and (max-width: 767px) {
  .header-search-box {
    display: none;
  }
  .search-items {
    width: auto;
  }
}

@media screen and (width: 912px) {
  .search-items {
    width: 50vw;
  }
}
@media screen and (width: 1024px) {
  .search-items {
    width: 50vw;
  }
}
@media screen and (width: 1280px) {
  .search-items {
    width: 36vw;
  }
}

.search-box {
  display: flex;
  flex-direction: column;
  padding: 1px;
  border-radius: 20px;
  position: relative;
  margin-left: 5px;
  border: 2px solid #fff;
  width: 50vw;
}
.blog-detail {
  height: auto;
  width: 80vw;
  border: 2px solid black;
  border-radius: 20px;
  margin: 1rem auto;
  padding: 2rem;
  background-color: #faecf3;
}
.search-bar {
  background: #fff;
  border: none;
  padding: 15px;
  height: 30px;
  border-radius: 20px;
}
.blog-details {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  margin: 30px auto;
}
.blog-details p {
  width: 80vw;
}
.blog-details-img {
  height: 400px;
  width: 500px;
  margin: 10px auto;
  border-radius: 10px;
}
@media screen and (max-width: 820px) {
  .search-box {
    width: 320px;
  }
  .search-items {
    width: 320px;
  }
}

@media screen and (min-width: 1201px) {
  .search-box {
    width: 460px;
  }
  .search-items {
    width: 460px;
  }
}
@media screen and (width: 540px) {
  .search-items {
    width: auto;
  }
}
.search-item-img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 3px;
  margin-right: 7px;
  flex-shrink: 0;
}

.searchItem {
  color: #5f5b5b;
  padding: 3px;
  margin: 2px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.searchItem:hover {
  background-color: #e8e6e1;
}

.results-list {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 8px #ddd;
  border-radius: 10px;
  margin-top: 1rem;
  max-height: 300px;
  overflow-y: auto;
}

.search-result {
  padding: 10px 20px;
}

.search-result:hover {
  background-color: #efefef;
}

.loader_bg {
  height: 100vh;
  justify-content: center !important;
  align-items: center !important;
  background-color: var(--bg);
  display: flex !important;
  flex-direction: column !important;
}

.message {
  height: 1.5em;
  position: relative;
  width: 100%;
}

.message__line {
  animation: 5s linear message-fade-in-out;
  opacity: 0;
  position: absolute;
  inset: 0;
}

.message__line:nth-child(2) {
  animation-delay: 5s;
}

.message__line:nth-child(3) {
  animation-delay: 10s;
}

.message__line:nth-child(4) {
  animation-delay: 15s;
}

.message__line:nth-child(5) {
  animation-delay: 20s;
}

.message__line:nth-child(6) {
  animation-delay: 25s;
}

.message__line:nth-child(7) {
  animation-delay: 30s;
}

.message__line:last-child {
  animation-name: message-fade-in;
  animation-delay: 35s;
  animation-fill-mode: forwards;
}

.tower,
.tower__brick,
.tower__brick-layer,
.tower__brick-side,
.tower__brick-stud,
.tower__group {
  transform-style: preserve-3d;
}

.tower {
  margin: 0 auto 1.5em;
  position: relative;
  perspective: 800px;
  width: 16em;
  height: 16em;
}

.tower__brick,
.tower__brick-layer,
.tower__brick-side,
.tower__brick-stud,
.tower__group {
  position: absolute;
}

.tower__brick,
.tower__brick-side,
.tower__group {
  animation-duration: 16s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.tower__brick,
.tower__brick-side {
  background-color: var(--primary);
}

.tower__brick {
  background-image: radial-gradient(
    100% 100% at center,
    hsla(0, 0%, 0%, 0.3) 0.3em,
    hsla(0, 0%, 0%, 0) 0.3em
  );
  background-size: 1em 1em;
  width: 2em;
  height: 1em;
}

.tower__brick-layer--4 {
  transform: translateZ(4.8em);
}

.tower__brick-layer--3 {
  transform: translateZ(3.6em);
}

.tower__brick-layer--2 {
  transform: translateZ(2.4em);
}

.tower__brick-layer--1 {
  transform: translateZ(1.2em);
}

.tower__brick-layer---1 {
  transform: translateZ(-1.2em);
}

.tower__brick-layer---2 {
  transform: translateZ(-2.4em);
}

.tower__brick-layer---3 {
  transform: translateZ(-3.6em);
}

.tower__brick-side {
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 1.2em;
  transform: rotateX(90deg);
  transform-origin: 50% 100%;
}

.tower__brick-side:nth-child(2) {
  top: 0;
  bottom: auto;
  left: 100%;
  width: 1.2em;
  height: 100%;
  transform: rotateY(90deg);
  transform-origin: 0 50%;
}

.tower__brick-side:nth-child(3) {
  top: 100%;
  left: 0;
  width: 100%;
  height: 1.2em;
  transform: rotateX(-90deg);
  transform-origin: 50% 0;
}

.tower__brick-side:nth-child(4) {
  top: 0;
  right: 100%;
  bottom: auto;
  left: auto;
  width: 1.2em;
  height: 100%;
  transform: rotateY(-90deg);
  transform-origin: 100% 50%;
}

.tower__brick--135 .tower__brick-side:nth-child(odd),
.tower__brick--270 .tower__brick-side:nth-child(odd),
.tower__brick--315 .tower__brick-side:nth-child(odd),
.tower__brick--90 .tower__brick-side:nth-child(odd),
.tower__brick-side:nth-child(2n) {
  animation-name: brick-side-1;
  filter: brightness(0.5);
}

.tower__brick--135 .tower__brick-side:nth-child(2n),
.tower__brick--270 .tower__brick-side:nth-child(2n),
.tower__brick--315 .tower__brick-side:nth-child(2n),
.tower__brick--90 .tower__brick-side:nth-child(2n),
.tower__brick-side:nth-child(odd) {
  animation-name: brick-side-2;
  filter: brightness(0.75);
}

.tower__brick-stud {
  background-color: inherit;
  border-radius: 50%;
  top: 0.2em;
  left: 0.2em;
  width: 0.6em;
  height: 0.6em;
  transform: translateZ(0.2em);
}

.tower__brick-stud:nth-child(6) {
  left: 1.2em;
}

.tower__brick--0 {
  transform: translate3d(-1.5em, -1.5em, 0);
}

.tower__brick--45 {
  transform: translate3d(-0.5em, -1.5em, 0);
}

.tower__brick--90 {
  transform: translate3d(0, -1em, 0) rotateZ(90deg);
}

.tower__brick--135 {
  transform: translate3d(0, 0, 0) rotateZ(90deg);
}

.tower__brick--180 {
  transform: translate3d(-0.5em, 0.5em, 0);
}

.tower__brick--225 {
  transform: translate3d(-1.5em, 0.5em, 0);
}

.tower__brick--270 {
  transform: translate3d(-2em, 0, 0) rotateZ(-90deg);
}

.tower__brick--315 {
  transform: translate3d(-2em, -1em, 0) rotateZ(-90deg);
}

.tower__brick--red,
.tower__brick--red .tower__brick-side {
  background-color: var(--red);
}

.tower__brick--orange,
.tower__brick--orange .tower__brick-side {
  background-color: var(--orange);
}

.tower__brick--green,
.tower__brick--green .tower__brick-side {
  background-color: var(--green);
}

.tower__brick--purple,
.tower__brick--purple .tower__brick-side {
  background-color: var(--purple);
}

.tower__brick--magenta,
.tower__brick--magenta .tower__brick-side {
  background-color: var(--magenta);
}

.tower__brick--move1 {
  animation-name: brick-move-1;
}

.tower__brick--move2 {
  animation-name: brick-move-2;
}

.tower__brick--move3 {
  animation-name: brick-move-3;
}

.tower__brick--move4 {
  animation-name: brick-move-4;
}

.tower__brick--move5 {
  animation-name: brick-move-5;
}

.tower__brick--move6 {
  animation-name: brick-move-6;
}

.tower__brick--move7 {
  animation-name: brick-move-7;
}

.tower__brick--move8 {
  animation-name: brick-move-8;
}

.tower__brick--move9 {
  animation-name: brick-move-9;
}

.tower__brick--move10 {
  animation-name: brick-move-10;
}

.tower__brick--move11 {
  animation-name: brick-move-11;
}

.tower__brick--move12 {
  animation-name: brick-move-12;
}

.tower__brick--move13 {
  animation-name: brick-move-13;
}

.tower__brick--move14 {
  animation-name: brick-move-14;
}

.tower__brick--move15 {
  animation-name: brick-move-15;
}

.tower__brick--move16 {
  animation-name: brick-move-16;
}

.tower__group {
  animation-name: brick-group;
  top: 50%;
  left: 50%;
  transform: rotateX(45deg) rotateZ(45deg);
}

.button_loader {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 25px;
  animation: 2s linear infinite spin;
  border-top: 14px solid #000;
  border-bottom: 14px solid red;
  border-right: 14px solid green;
  border-left: 14px solid pink;
}

.add_address_button,
.address_box {
  margin-bottom: 10px;
  cursor: pointer;
}

.button_loader.margin-set {
  margin-top: 10px;
  margin-right: 100px;
}

.button_loader.margin-button-set {
  margin: auto;
}

.button_loader.button_pay {
  margin-top: auto;
  margin-bottom: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 90%, 10%);
    --fg: hsl(var(--hue), 90%, 90%);
  }
}

@keyframes brick-group {
  from {
    transform: rotateX(45deg) rotateZ(0.125turn) translateZ(0);
  }

  to {
    transform: rotateX(45deg) rotateZ(2.125turn) translateZ(-4.8em);
  }
}

@keyframes brick-side-1 {
  25%,
  50%,
  75%,
  from,
  to {
    filter: brightness(0.5);
  }

  12.5%,
  37.5%,
  62.5%,
  87.5% {
    filter: brightness(0.75);
  }
}

@keyframes brick-side-2 {
  25%,
  50%,
  75%,
  from,
  to {
    filter: brightness(0.75);
  }

  12.5%,
  37.5%,
  62.5%,
  87.5% {
    filter: brightness(0.5);
  }
}

@keyframes brick-move-1 {
  from {
    animation-timing-function: ease-in;
    transform: translate3d(0, 0, 0) rotateZ(90deg);
  }

  1.25% {
    animation-timing-function: linear;
    transform: translate3d(0, 0, -0.4em) rotateZ(90deg);
  }

  2.5% {
    transform: translate3d(2em, 0, -0.4em) rotateZ(90deg);
  }

  3.75% {
    transform: translate3d(2em, 0, 10em) rotateZ(90deg);
  }

  5% {
    animation-timing-function: ease-out;
    transform: translate3d(0, 0, 10em) rotateZ(90deg);
  }

  6.25%,
  to {
    transform: translate3d(0, 0, 9.6em) rotateZ(90deg);
  }
}

@keyframes brick-move-2 {
  6.25%,
  from {
    animation-timing-function: ease-in;
    transform: translate3d(-0.5em, -1.5em, 0);
  }

  7.5% {
    animation-timing-function: linear;
    transform: translate3d(-0.5em, -1.5em, -0.4em);
  }

  8.75% {
    transform: translate3d(-0.5em, -3.5em, -0.4em);
  }

  10% {
    transform: translate3d(-0.5em, -3.5em, 10em);
  }

  11.25% {
    animation-timing-function: ease-out;
    transform: translate3d(-0.5em, -1.5em, 10em);
  }

  12.5%,
  to {
    transform: translate3d(-0.5em, -1.5em, 9.6em);
  }
}

@keyframes brick-move-3 {
  12.5%,
  from {
    animation-timing-function: ease-in;
    transform: translate3d(-2em, -1em, 0) rotateZ(-90deg);
  }

  13.75% {
    animation-timing-function: linear;
    transform: translate3d(-2em, -1em, -0.4em) rotateZ(-90deg);
  }

  15% {
    transform: translate3d(-4em, -1em, -0.4em) rotateZ(-90deg);
  }

  16.25% {
    transform: translate3d(-4em, -1em, 10em) rotateZ(-90deg);
  }

  17.5% {
    animation-timing-function: ease-out;
    transform: translate3d(-2em, -1em, 10em) rotateZ(-90deg);
  }

  18.75%,
  to {
    transform: translate3d(-2em, -1em, 9.6em) rotateZ(-90deg);
  }
}

@keyframes brick-move-4 {
  18.75%,
  from {
    animation-timing-function: ease-in;
    transform: translate3d(-1.5em, 0.5em, 0);
  }

  20% {
    animation-timing-function: linear;
    transform: translate3d(-1.5em, 0.5em, -0.4em);
  }

  21.25% {
    transform: translate3d(-1.5em, 2.5em, -0.4em);
  }

  22.5% {
    transform: translate3d(-1.5em, 2.5em, 10em);
  }

  23.75% {
    animation-timing-function: ease-out;
    transform: translate3d(-1.5em, 0.5em, 10em);
  }

  25%,
  to {
    transform: translate3d(-1.5em, 0.5em, 9.6em);
  }
}

@keyframes brick-move-5 {
  25%,
  from {
    animation-timing-function: ease-in;
    transform: translate3d(0, -1em, 0) rotateZ(90deg);
  }

  26.25% {
    animation-timing-function: linear;
    transform: translate3d(0, -1em, -0.4em) rotateZ(90deg);
  }

  27.5% {
    transform: translate3d(2em, -1em, -0.4em) rotateZ(90deg);
  }

  28.75% {
    transform: translate3d(2em, -1em, 10em) rotateZ(90deg);
  }

  30% {
    animation-timing-function: ease-out;
    transform: translate3d(0, -1em, 10em) rotateZ(90deg);
  }

  31.25%,
  to {
    transform: translate3d(0, -1em, 9.6em) rotateZ(90deg);
  }
}

@keyframes brick-move-6 {
  31.25%,
  from {
    animation-timing-function: ease-in;
    transform: translate3d(-1.5em, -1.5em, 0);
  }

  32.5% {
    animation-timing-function: linear;
    transform: translate3d(-1.5em, -1.5em, -0.4em);
  }

  33.75% {
    transform: translate3d(-1.5em, -3.5em, -0.4em);
  }

  35% {
    transform: translate3d(-1.5em, -3.5em, 10em);
  }

  36.25% {
    animation-timing-function: ease-out;
    transform: translate3d(-1.5em, -1.5em, 10em);
  }

  37.5%,
  to {
    transform: translate3d(-1.5em, -1.5em, 9.6em);
  }
}

@keyframes brick-move-7 {
  37.5%,
  from {
    animation-timing-function: ease-in;
    transform: translate3d(-2em, 0, 0) rotateZ(-90deg);
  }

  38.75% {
    animation-timing-function: linear;
    transform: translate3d(-2em, 0, -0.4em) rotateZ(-90deg);
  }

  40% {
    transform: translate3d(-4em, 0, -0.4em) rotateZ(-90deg);
  }

  41.25% {
    transform: translate3d(-4em, 0, 10em) rotateZ(-90deg);
  }

  42.5% {
    animation-timing-function: ease-out;
    transform: translate3d(-2em, 0, 10em) rotateZ(-90deg);
  }

  43.75%,
  to {
    transform: translate3d(-2em, 0, 9.6em) rotateZ(-90deg);
  }
}

@keyframes brick-move-8 {
  43.75%,
  from {
    animation-timing-function: ease-in;
    transform: translate3d(-0.5em, 0.5em, 0);
  }

  45% {
    animation-timing-function: linear;
    transform: translate3d(-0.5em, 0.5em, -0.4em);
  }

  46.25% {
    transform: translate3d(-0.5em, 2.5em, -0.4em);
  }

  47.5% {
    transform: translate3d(-0.5em, 2.5em, 10em);
  }

  48.75% {
    animation-timing-function: ease-out;
    transform: translate3d(-0.5em, 0.5em, 10em);
  }

  50%,
  to {
    transform: translate3d(-0.5em, 0.5em, 9.6em);
  }
}

@keyframes brick-move-9 {
  50%,
  from {
    animation-timing-function: ease-in;
    transform: translate3d(0, 0, 0) rotateZ(90deg);
  }

  51.25% {
    animation-timing-function: linear;
    transform: translate3d(0, 0, -0.4em) rotateZ(90deg);
  }

  52.5% {
    transform: translate3d(2em, 0, -0.4em) rotateZ(90deg);
  }

  53.75% {
    transform: translate3d(2em, 0, 10em) rotateZ(90deg);
  }

  55% {
    animation-timing-function: ease-out;
    transform: translate3d(0, 0, 10em) rotateZ(90deg);
  }

  56.25%,
  to {
    transform: translate3d(0, 0, 9.6em) rotateZ(90deg);
  }
}

@keyframes brick-move-10 {
  56.25%,
  from {
    animation-timing-function: ease-in;
    transform: translate3d(-0.5em, -1.5em, 0);
  }

  57.5% {
    animation-timing-function: linear;
    transform: translate3d(-0.5em, -1.5em, -0.4em);
  }

  58.75% {
    transform: translate3d(-0.5em, -3.5em, -0.4em);
  }

  60% {
    transform: translate3d(-0.5em, -3.5em, 10em);
  }

  61.25% {
    animation-timing-function: ease-out;
    transform: translate3d(-0.5em, -1.5em, 10em);
  }

  62.5%,
  to {
    transform: translate3d(-0.5em, -1.5em, 9.6em);
  }
}

@keyframes brick-move-11 {
  62.5%,
  from {
    animation-timing-function: ease-in;
    transform: translate3d(-2em, -1em, 0) rotateZ(-90deg);
  }

  63.75% {
    animation-timing-function: linear;
    transform: translate3d(-2em, -1em, -0.4em) rotateZ(-90deg);
  }

  65% {
    transform: translate3d(-4em, -1em, -0.4em) rotateZ(-90deg);
  }

  66.25% {
    transform: translate3d(-4em, -1em, 10em) rotateZ(-90deg);
  }

  67.5% {
    animation-timing-function: ease-out;
    transform: translate3d(-2em, -1em, 10em) rotateZ(-90deg);
  }

  68.75%,
  to {
    transform: translate3d(-2em, -1em, 9.6em) rotateZ(-90deg);
  }
}

@keyframes brick-move-12 {
  68.75%,
  from {
    animation-timing-function: ease-in;
    transform: translate3d(-1.5em, 0.5em, 0);
  }

  70% {
    animation-timing-function: linear;
    transform: translate3d(-1.5em, 0.5em, -0.4em);
  }

  71.25% {
    transform: translate3d(-1.5em, 2.5em, -0.4em);
  }

  72.5% {
    transform: translate3d(-1.5em, 2.5em, 10em);
  }

  73.75% {
    animation-timing-function: ease-out;
    transform: translate3d(-1.5em, 0.5em, 10em);
  }

  75%,
  to {
    transform: translate3d(-1.5em, 0.5em, 9.6em);
  }
}

@keyframes brick-move-13 {
  75%,
  from {
    animation-timing-function: ease-in;
    transform: translate3d(0, -1em, 0) rotateZ(90deg);
  }

  76.25% {
    animation-timing-function: linear;
    transform: translate3d(0, -1em, -0.4em) rotateZ(90deg);
  }

  77.5% {
    transform: translate3d(2em, -1em, -0.4em) rotateZ(90deg);
  }

  78.75% {
    transform: translate3d(2em, -1em, 10em) rotateZ(90deg);
  }

  80% {
    animation-timing-function: ease-out;
    transform: translate3d(0, -1em, 10em) rotateZ(90deg);
  }

  81.25%,
  to {
    transform: translate3d(0, -1em, 9.6em) rotateZ(90deg);
  }
}

@keyframes brick-move-14 {
  81.25%,
  from {
    animation-timing-function: ease-in;
    transform: translate3d(-1.5em, -1.5em, 0);
  }

  82.5% {
    animation-timing-function: linear;
    transform: translate3d(-1.5em, -1.5em, -0.4em);
  }

  83.75% {
    transform: translate3d(-1.5em, -3.5em, -0.4em);
  }

  85% {
    transform: translate3d(-1.5em, -3.5em, 10em);
  }

  86.25% {
    animation-timing-function: ease-out;
    transform: translate3d(-1.5em, -1.5em, 10em);
  }

  87.5%,
  to {
    transform: translate3d(-1.5em, -1.5em, 9.6em);
  }
}

@keyframes brick-move-15 {
  87.5%,
  from {
    animation-timing-function: ease-in;
    transform: translate3d(-2em, 0, 0) rotateZ(-90deg);
  }

  88.75% {
    animation-timing-function: linear;
    transform: translate3d(-2em, 0, -0.4em) rotateZ(-90deg);
  }

  90% {
    transform: translate3d(-4em, 0, -0.4em) rotateZ(-90deg);
  }

  91.25% {
    transform: translate3d(-4em, 0, 10em) rotateZ(-90deg);
  }

  92.5% {
    animation-timing-function: ease-out;
    transform: translate3d(-2em, 0, 10em) rotateZ(-90deg);
  }

  93.75%,
  to {
    transform: translate3d(-2em, 0, 9.6em) rotateZ(-90deg);
  }
}

@keyframes brick-move-16 {
  93.75%,
  from {
    animation-timing-function: ease-in;
    transform: translate3d(-0.5em, 0.5em, 0);
  }

  95% {
    animation-timing-function: linear;
    transform: translate3d(-0.5em, 0.5em, -0.4em);
  }

  96.25% {
    transform: translate3d(-0.5em, 2.5em, -0.4em);
  }

  97.5% {
    transform: translate3d(-0.5em, 2.5em, 10em);
  }

  98.75% {
    animation-timing-function: ease-out;
    transform: translate3d(-0.5em, 0.5em, 10em);
  }

  to {
    transform: translate3d(-0.5em, 0.5em, 9.6em);
  }
}

@keyframes message-fade-in {
  from {
    opacity: 0;
  }

  6%,
  to {
    opacity: 1;
  }
}

@keyframes message-fade-in-out {
  from,
  to {
    opacity: 0;
  }

  6%,
  94% {
    opacity: 1;
  }
}

.address_box {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
}

.add_address_button:hover,
.address_box:hover {
  background-color: #f2f2f2;
}

.address_box.active {
  border: 2px solid #f2acb9;
}

/* Tooltip Container */
.tooltip-container {
  position: relative;
  display: inline-block;
}

/* Button Styles */
.redeem-button {
  border: none;
  padding: 10px;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  font-weight: 700;
  margin: 1rem 0;
}

.redeem-button.disabled {
  background: #ffbdd1;
  cursor: not-allowed;
}

.redeem-button.enabled {
  background: #d05278;
}

.spread-referral-msg {
  max-width: 99%;
  font-weight: 500;
  color: #333;
  margin: 1rem 0;
  color: #3B56A6;
  background-image: radial-gradient(circle, #3B56A6 23%, #C7273B 76%, #2F2E6E 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize;
}

@media screen and (max-width: 768px) {
  .spread-referral-msg {
    font-weight: 700;
    max-width: 200px;
  }
}

/* Tooltip Styles */
.tooltip {
  position: absolute;
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  whitespace: nowrap;
  z-index: 1; /* Ensure the tooltip is above other content */
}

/* Optional: Adding a small arrow pointing upwards towards the button */
.tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent; /* Arrow color matches tooltip background */
}

.toys-adored-customer {
  font-size: 40px;
  padding: 50px 0px;
  text-align: center;
  font-weight: 700;
}

.add_address_button {
  background-color: #f2acb9;
  padding: 8px 10px;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  margin-top: 10px;
}

.instagram-feed {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 10px;
}

.gallery-container {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.gallery-post {
  width: calc(33.33% - 20px);
  border-radius: 20px;
  padding: 10px;
  position: relative;
}

.gallery-media img,
.gallery-media video {
  overflow: hidden;
  transition: box-shadow 0.3s;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 20px;
  width: 100%;
  height: 50%;
  object-fit: cover;
  display: block;
}

.gallery-caption {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.4;
  color: #333;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.optInputDiv {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.OtpInputStyle {
  width: 45px !important;
  height: 45px;
  border-radius: 20px;
  border: 0;
  margin-left: 8px;
  margin-right: 8px;
  background: #fff;
  font-size: 20px;
}

.OtpInputStyle:focus {
  border: 1px solid rgba(17, 10, 10, 0.363);
}

@media screen and (max-width: 400px) {
  .bread-crumb {
    font-size: 46px;
  }
}
.header-options {
  width: 200px;
  display: flex;
  gap: 3px;
}

@media screen and (max-width: 768px) {
  .gallery-post {
    width: 100%;
  }
  .bread-crumb {
    font-size: 46px;
  }

  .header-options {
    display: flex;
    width: 180px;
    height: 40px;
  }

  .header-items {
    flex-direction: column;
    height: 100px;
    justify-content: center;
  }
  .toys-adored-customer {
    font-size: 30px;
    font-weight: bold;
  }
}

/* 
.instagram-feed {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .instagram-post {
    border: 1px solid #e1e1e1;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: calc(33.33% - 20px);
    padding: 15px;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .instagram-post video {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  .caption {
    margin-top: 10px;
    font-size: 14px;
    color: #666;
  }

   */
/*   


   .instagram-feed {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
  }
  
  .instagram-post {
    flex: 1 0 calc(33.33% - 20px);
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s;
  }
  
  .instagram-post:hover {
    transform: translateY(-5px);
  }
  
  .instagram-post img,
  .instagram-post video {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  .caption {
    margin-top: 10px;
    font-size: 14px;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .instagram-post {
      flex: 1 0 calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .instagram-post {
      flex: 1 0 calc(100% - 20px);
    }
  }
   */


   /* new loader */
   .spinner-loader {
    width: 48px;
    overflow: visible;
    transform: rotate(-90deg);
    transform-origin: center;
  
    --active: #F72C5B;
    --track: #f2d4fe;
  
    --duration: 8s;
  
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      rotate: 0deg;
    }
  
    100% {
      rotate: 360deg;
    }
  }
  
  .active {
    stroke: var(--active);
    stroke-linecap: round;
    stroke-dashoffset: 360;
    animation: active-animation var(--duration) ease-in-out infinite;
  }
  
  @keyframes active-animation {
    0% {
      stroke-dasharray: 0 0 0 360 0 360;
    }
    12.5% {
      stroke-dasharray: 0 0 270 90 270 90;
    }
    25% {
      stroke-dasharray: 0 270 0 360 0 360;
    }
    37.5% {
      stroke-dasharray: 0 270 270 90 270 90;
    }
    50% {
      stroke-dasharray: 0 540 0 360 0 360;
    }
    50.001% {
      stroke-dasharray: 0 180 0 360 0 360;
    }
    62.5% {
      stroke-dasharray: 0 180 270 90 270 90;
    }
    75% {
      stroke-dasharray: 0 450 0 360 0 360;
    }
    87.5% {
      stroke-dasharray: 0 450 270 90 270 90;
    }
    87.501% {
      stroke-dasharray: 0 90 270 90 270 90;
    }
    100% {
      stroke-dasharray: 0 360 1 360 0 360;
    }
  }
  
  .track {
    stroke: var(--track);
    stroke-linecap: round;
    stroke-dashoffset: 360;
    animation: track-animation var(--duration) ease-in-out infinite;
  }
  
  @keyframes track-animation {
    0% {
      stroke-dasharray: 0 20 320 40 320 40;
    }
    12.5% {
      stroke-dasharray: 0 290 50 310 50 310;
    }
    25% {
      stroke-dasharray: 0 290 320 40 320 40;
    }
    37.5% {
      stroke-dasharray: 0 560 50 310 50 310;
    }
    37.501% {
      stroke-dasharray: 0 200 50 310 50 310;
    }
    50% {
      stroke-dasharray: 0 200 320 40 320 40;
    }
    62.5% {
      stroke-dasharray: 0 470 50 310 50 310;
    }
    62.501% {
      stroke-dasharray: 0 110 50 310 50 310;
    }
    75% {
      stroke-dasharray: 0 110 320 40 320 40;
    }
    87.5% {
      stroke-dasharray: 0 380 50 310 50 310;
    }
    100% {
      stroke-dasharray: 0 380 320 40 320 40;
    }
  }

 .checkout-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
 }

 @media (max-width: 768px) {
  .checkout-buttons {
    flex-direction: column;
  }
 }

 /* Referral */
  