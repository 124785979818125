@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'base/_reset';
@import 'base/_fonts';
@import 'components/_jquery.formstyler';
@import 'components/_ion.rangeSlider.min';
@import 'components/_slick';
@import 'layout/_header';
@import 'layout/_footer';
@import 'layout/_content';
@import url('https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');
@import 'rc-slider/assets/index.css';
@import 'react-dropdown/style.css';

#__next {
  overflow: hidden !important;
}
