.react-datepicker{
    border: none!important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
    padding: 10px!important;
    border-radius: 15px!important;
}

.custom-datepicker .react-datepicker__day--disabled {
    position: relative;
}

.custom-datepicker .react-datepicker__day--disabled::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #D3D3D3;
    font-weight: 600;
}
.react-datepicker__current-month{
    margin-top: -5px!important;
}

.react-datepicker__day--disabled {
    background-color: transparent!important;
    border-radius: 20%;
    color: #c4c4c4!important;
    font-weight: 600;
    border: none!important;
}

.react-datepicker__day{
    border-radius: 20%;
    color: #3F8DF4;
    font-weight: 600;
    border: 1px solid #3F8DF4;
}
.react-datepicker__day:hover{
    background-color: #3F8DF4!important;
}

.react-datepicker__triangle{
    display: none;
}

.react-datepicker__navigation-icon::before{
    border-color: #000!important;
}


.react-datepicker__navigation{
    border: 1px solid lightgrey!important;
    background-color: #fff!important;
    border-radius: 10px;
    color: #000;
    margin: 10px 30px 10px 30px!important;
}


.custom-datepicker{
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    box-shadow: inset 0 3px 6px rgba(0,0,0,0.1);
    width: 40px;
    padding: 10px 15px;
    cursor: pointer;
    background:#F0F9FF url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)  97% 50% no-repeat ;
    background-position: center;
    color: transparent;
}
.custom-datepicker::-webkit-inner-spin-button {
    display: none;
}
.custom-datepicker::-webkit-calendar-picker-indicator {
    opacity: 0;
}
.react-datepicker__header{
    background-color: #fff !important;
    border: none!important;
    padding: 10px!important;
    margin: 10px!important;
}
.react-datepicker__navigation-icon::before{
    height: 7px!important;
    width: 7px!important;
    right: -5px!important;
}


